import React from 'react';
import EventItem from './EventItem';
import { events } from './events';
import './EventList.css';

const EventList = () => {
  return (
    <div id="events" className='Event-description'>
      <h2>Our Events</h2>
      <div className='EventList'>
        {events.map((item, index) => (
          <EventItem key={index} event={item} />
        ))}
      </div>
    </div>
  );
};

export default EventList;
