
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getFirestore} from 'firebase/firestore';

import {key,auth,projectid,bucket,measurement,messaging,id} from './gateway'

const firebaseConfig = {
  apiKey: "AIzaSyC3DJkcRoI5HkoTWg178K2v5FalHgka-O4",
  authDomain: "cpuisimm-8882b.firebaseapp.com",
  projectId: "cpuisimm-8882b",
  storageBucket: "cpuisimm-8882b.appspot.com",
  messagingSenderId: "590076919208",
  appId: "1:590076919208:web:bf3a6c17bcc5efb303719d",
  measurementId: "G-WNXV84SM0S"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app) ;
const analytics = getAnalytics(app);
export { app, db }; // Export the storage along with app and db