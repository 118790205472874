import React, { useEffect, useState } from 'react'
import "./Sponsors.css"
import { db } from '../../config/config'
import { collection, getDocs } from 'firebase/firestore'
import testImg from "../../img/specification/fighter.jpg"

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import { Autoplay } from 'swiper/modules';

export default function Sponsors() {
    const [sponsors, setSponsors] = useState([])
    const settings = {
        slidestoshow: 3,
        autoplay: {
            delay: 10,
            disableOnInteraction: false,
        },
        modules: [Autoplay],
    };

    useEffect(() => {
        const Ref = collection(db, 'sponsors');
        const getData = async () => {
            try {
                const response = await getDocs(Ref);
                const fetchedData = response.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }));
                setSponsors(fetchedData);
            } catch (error) {
                console.log(error);
            }
        };
        getData();
    }, []);

    if (sponsors.length === 0)
        return <></>
    return (
        <div id="sponsors" className="sponsors">
            <h2 className='titlevent'>Our Sponsors</h2>
            <div className='sponsordiv'>
                <div className='sponsors-slide'>
                    <Swiper
                            slidesPerView={Math.max(Math.floor((window.innerWidth - 60) / 120), 1)}
                            loop = {true}
                            loopAdditionalSlides={2}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            modules = {[Autoplay]}
                        >
                        {sponsors.length > 0 && (
                            sponsors.map((item, i) =>
                                <SwiperSlide key={i} className='sponsor-logo'><img src={item.data.cover} alt={item.data.name} /></SwiperSlide>
                            )
                        )}
                        {sponsors.length > 0 && (Math.max(Math.floor((window.innerWidth - 60) / 120), 1) < sponsors.length) && (
                            sponsors.map((item, i) =>
                                <SwiperSlide key={"i"+i} className='sponsor-logo'><img src={item.data.cover} alt={item.data.name} /></SwiperSlide>
                            )
                        )}
                    </Swiper>
                </div>
            </div>
        </div>
    )
}
