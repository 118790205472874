import React from 'react'
import "./Alert.css"

export default function Alert({msg="This a custom alert!", handleOk}) {
    return (
        <div id="customAlert" className="alertContainer">
            <div className="alertBox">
                <p>
                    <span className="alertIcon">i</span>
                    <span>{msg}</span>
                </p>
                <button onClick={handleOk}>Ok</button>
            </div>
        </div>
    )
}
