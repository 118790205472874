import React, { useEffect, useState } from 'react'
import './styles/Specification.css'
import SpecificationItem from './SpecificationItem'
import { db } from '../../config/config'
import { collection, getDocs } from 'firebase/firestore'
import { key, id } from '../../config/gateway'

const Specification = () => {
  const [specification, setSpecification] = useState([])
  useEffect(() => {
    const Ref = collection(db, 'specifications');

    const getData = async () => {
      try {
        const response = await getDocs(Ref);
        const fetchedData = response.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }));




        fetchedData.sort((a, b) => a.data.rank - b.data.rank);
        setSpecification(fetchedData);
      } catch (error) {
        console.log(error);
      }
    };

    getData();
  }, []);

  if (specification.length === 0) return <> </>
  return (
    <div className='Specification' id='specifications'>
      <h5>Specifications</h5>
      <div className='Specification-container'>
        {specification.length > 0 ? (
          specification.map((item) =>
            <SpecificationItem key={item.id} item={item} />
          )
        ) : (
          null
        )}
      </div>
    </div>
  )
}

export default Specification