import React, { useState, useEffect } from 'react';
import './EventItem.css';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import clock from '../../img/cyberbot/clock.png'
import { useNavigate } from 'react-router-dom';

const EventItem = ({ event }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: '0px 0px -100px 0px',
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const navigate = useNavigate()
  const goToEvent = () => {
    navigate(`${event.path}`);
    document.scrollingElement.scrollTop = 0;
  }

  return (
    <motion.div
      className='Event-Card'
      animate={controls}
      initial='hidden'
      ref={ref}
      variants={{
        hidden: { opacity: 0, y: -20 },
        visible: { opacity: 1, y: 0 },
      }}
    >
      <div className='Event-pic'>
        <img src={event.image} alt='' className='img-card' />
      </div>
      <div className='eventDetail'>
        <h1>{event.name}</h1>
        <p style={{display:'flex', alignItems:'center'}}>
          <img src={clock} loading='lazy' alt='clock' />
          <span>{event.date}</span>
        </p>
        <div className='event-description'>
          <p>
            {event.description.substring(0, 125)} <span>...</span>
          </p>
        </div>
      </div>
      {event.passed ? 
      (
        <button className='btn-explore' onClick={goToEvent}>
          Explore
        </button>
      ) : 
      (
        <a href='https://www.facebook.com/events/3104013909749213'>
          <button className='btn-explore coming'>
            Explore
          </button>
        </a> 
      )}
    </motion.div>
  );
};

export default EventItem;
