import cyber1 from '../../img/cyberbot/cyberbotv1.jpg'
import cyber2 from '../../img/cyberbot/cyberbotv2.jpg'
import cyber3 from '../../img/cyberbot/cyberbotv3.jpg'
import cyber4 from '../../img/cyberbot/cyberbotv4.png'

export const events = [
    {
        image: cyber4,
        name: "ISIMM-CYBERBOT v4.0",
        date: "April 21, 2024",
        passed : false,
        description: "After The Birth of the Beast ignited the spark of innovation, and Unleash the Beast pushed the limits of creativity, the challenge escalated in Face the Beast, where competitors had to confront the fiercest test yet. Now, the fourth edition, Legacy of the Beast, takes us to the next level. This year’s competition honors the journey of past participants while pushing new boundaries. Teams will not only compete for victory but for the chance to leave their mark on the legendary path forged by the Beast"
    },
    {
        image: cyber3,
        name: "ISIMM-CYBERBOT v3.0",
        date: "April 21, 2024",
        passed : true,
        path:"events/v3_0",
        description: "ISIMM-CYBERBOT v3.0 stands as an international event designed to facilitate the gathering of passionate and competitive individuals, fostering the improvement of their abilities, expanding their knowledge, and optimizing their robots' performance."
    },
    {
        image: cyber2,
        name: "ISIMM-CYBERBOT v2.0",
        date: "February 12, 2023",
        passed : true,
        path:"events/v2_0",
        description: "ISIMM-CYBERBOT v2.0 has transformed into an international gathering, providing an avenue for enthusiastic and competitive individuals to enhance their skills, broaden their knowledge, and optimize the performance of their robots."
    },
    {
        image: cyber1,
        name: "ISIMM-CYBERBOT v1.0",
        date: "March 13, 2022",
        passed : true,
        path:"events/v1_0",
        description: "ISIMM-CYBERBOT v1.0 was an inaugural international event aimed at bringing together passionate and competitive individuals to enhance their skills, knowledge, and the performance of their robots."
    },
    
]