import React from 'react'
import Cell from "./Cell/Cell"

import "./Timeline.css"

export default function Timeline() {
    return (
        <>
            <div id="trainings" className="trainings">
                <h1 className='titlevent'>Our Trainings</h1>
                <Cell title="Backend Development" date="01-20 Feb 2025" atRight={false} />
                <Cell title="Web Development" date="07-08 Dec 2024" atRight={false} />
                <Cell title="C++ Training" date="09-10 Nov 2024" atRight={false} />
                <Cell title="Soft Step" date="26-27 Oct 2024" atRight={true} />
                <Cell title="Hard Step" date="19-20 Oct 2024" atRight={true} />
                <div className="moreBtnRow">
                    <span className="moreBtn" onClick={(e)=>{
                        e.target.parentNode.parentNode.classList.add("expanded");
                        e.target.parentNode.parentNode.removeChild(e.target.parentNode);
                    }}>Read More</span>
                </div>
            </div>
        </>
    )
}
