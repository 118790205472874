import React, { useState } from 'react'
import './Contact.css'

const Contact = () => {
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const myemail = "team.mentorini@gmail.com"

  const handleSendEmail = () => {
    const mailtoLink = `mailto:${myemail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(message)}`;
    window.open(mailtoLink);
  };
  return (
    <div id="contact" className='Contact'>
        <div className='contact-form'>
            <h2>Send us a message</h2>
            <p>For any collaboration or partnership, 
                please do not hesitate to send us a message using the form below.
            </p> <br />
            <input type="text" placeholder='Enter your subject'
                value={subject}
                onChange={(e) => setSubject(e.target.value)}/>

            <textarea name="" id="" cols="15" rows="10" placeholder='Enter your message'
                value={message}
                onChange={(e) => setMessage(e.target.value)}></textarea>
            <button onClick={handleSendEmail}>Send Now</button>
        </div>
    </div>
  )
}

export default Contact