import React from 'react'
import "./Cell.css"
export default function Cell({title, date, atRight}) {
    return (
        <div className="Cell">
            <div className="left">
                <h4 className="title">{!atRight && title}</h4>
                <h5 className="description">{!atRight && date}</h5>
            </div>
            <span className="indicator">c</span>
            <div className="right">
                <h4 className="title">{atRight && title}</h4>
                <h5 className="description">{atRight && date}</h5>
            </div>
        </div>
    )
}
