import React from 'react';
import './EventPage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import NavBar from '../NavBar/NavBar';
import Footer from '../Footer/Footer';

import affiche from '../../img/cyberbot/cyberbotv1.jpg'


const EventPage = () => {
  return (
    // <div style={{backgroundColor:'#171F2E'}}>
    <div className="EventPageRoot">

      <NavBar />
      <div className="EventPage">
        <div className="event-container">
          <h1 className="title">ISIMM CYBERBOT V1.0</h1>
          <img src={affiche} className="video-wrapper" />
          <div className="event-details">
            {/* <div className="details-line">
            <p className="detail"><FontAwesomeIcon icon={faCalendarAlt} /> Date: 13-03-2022</p> <br/><br/>
            <p className="detail"><FontAwesomeIcon icon={faMapMarkerAlt} /> Location: Higher Institute of Informatics and Mathematics of Monastir</p>
          </div> */}
            <p className="description">
              <span><b>Description:</b></span>
              <br /><br />
              On March 13, 2022, the Higher Institute of Informatics and Mathematics of Monastir (ISIMM) hosted the inaugural ISIMM-CYBERBOT v1.0 event, gathering passionate and competitive individuals in robotics. This international competition aimed to enhance participants' skills, deepen their knowledge, and optimize robot performance. It provided a platform for sharing best practices, exploring technological advancements, and fostering innovation and creativity. With sections including Fighter, All-terrain, Autonomous, and Junior, the event engaged participants in playful and experimental approaches, igniting their curiosity and interest in robotics. ISIMM-CYBERBOT v1.0 not only offered enthusiasts an opportunity to showcase their expertise but also served as a meeting point for those eager to explore the field, creating a vibrant community of robotics and electronics enthusiasts.

            </p>
          </div>
        </div>
      </div>
      <footer className="footer">
        <div className="footer__content">
          <Footer />
        </div>
      </footer>
    {/* <Footer/> */}

    </div>
  );
};

export default EventPage;