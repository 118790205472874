import React, { useState } from 'react'

import "./AboutUsSection.css"

export default function AboutUsSection() {
    return (
        <div id="aboutUs" className="aboutUs">
            <div className="main">
                <div className="rot">
                    <div className="bgAbout"></div>
                    <div className="bgAbout"></div>
                    <div className="bgAbout"></div>
                    <div className="bgAbout"></div>
                    <div className="left">
                        <div className="content">
                            <a className="title">About US</a>
                            <a className="description">Founded in 2018 by a group of students from the Department of Electronics and Computer Science at ISIMM, CPU-ISIMM is a non-profit cultural and scientific club that combines sought-after training and innovation. Today, our club has more than 40 members who manage it, spread across all departments of the Institute, expanding with each new intake in order to support and train over 200 students, providing them with knowledge and skills, and preparing them for a successful professional life.</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}