import React from 'react';
import './EventPage.css';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCalendarAlt, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import NavBar from '../NavBar/NavBar';
import Footer from '../Footer/Footer';

import affiche from '../../img/cyberbot/cyberbotv2.jpg'


const EventPagev2 = () => {
  return (
    <div className="EventPageRoot">
      <NavBar/>
      <div className="EventPage">
      <div className="event-container">
        <h1 className="title">ISIMM CYBERBOT V2.0</h1>
        <img src={affiche} className="video-wrapper" />
        <div className="event-details">
          {/* <div className="details-line">
            <p className="detail"><FontAwesomeIcon icon={faCalendarAlt} /> Date: 13-03-2022</p> <br/><br/>
            <p className="detail"><FontAwesomeIcon icon={faMapMarkerAlt} /> Location: Higher Institute of Informatics and Mathematics of Monastir</p>
          </div> */}
          <p className="description">
            <span><b>Description:</b></span>
            <br /><br />
            On February 12, 2023, the Higher Institute of Informatics and Mathematics of Monastir (ISIMM) proudly hosted the inaugural ISIMM-CYBERBOT v1.0 event, uniting enthusiastic and competitive individuals within the realm of robotics. This international competition was meticulously crafted to elevate participants' expertise, deepen their understanding, and refine the performance of their robotic creations. Embracing diverse sections such as Fighter, All-terrain, Autonomous, and Junior, the event fostered an environment of playful exploration and experimental learning, sparking curiosity and passion for robotics. Beyond merely showcasing technical prowess, ISIMM-CYBERBOT v1.0 served as a vibrant hub where like-minded individuals converged, fueling a dynamic community of robotics aficionados and innovators
          </p>
        </div>
      </div>
    </div>
    <footer className="footer">
      <div className="footer__content">
        <Footer />
      </div>
    </footer>
    </div>
  );
};

export default EventPagev2;